@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

.primary-action {
  margin-left: 5px;
}

.katex-html {
  display: none;
}

@media only screen and (max-width: 991px) {
  header .header-inner .header-right a.primary-action {
    display: none;
  }
}
